import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Logo from '../assets/MSSUS_Logo.png';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import './Header.css';
import NavbarList from './NavbarList';

function Header() {
  const [activeHash, setActiveHash] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      setActiveHash(hash);
      setMenuOpen(false);

      if (hash) {
        const element = document.getElementById(hash.replace('#', ''));
        if (element) {
          window.scrollTo({
            top: element.offsetTop - 120, // Scroll a bit further down, adjust as needed
            behavior: 'smooth',
          });
        }
      }
    };

    // Set the initial hash
    handleHashChange();

    // Listen for hash changes
    window.addEventListener('hashchange', handleHashChange);

    // Clean up event listener
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <Container className='header'>
        <div className='header-logo'>
          <a href='/'><img src={Logo} alt='logo' /></a>
        </div>
        <Navbar activeHash={activeHash} />
        <div className='nav-icon' onClick={toggleMenu}>
          <FontAwesomeIcon icon={faBars} size='lg' />
        </div>

      </Container>

      {menuOpen && (
        <div
          style={{
            position: 'absolute',
            top: '0', // Height of the header
            left: '0',
            backgroundColor: 'white',
            zIndex: '999',
            width: '100%',
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            boxShadow: '0 5px 10px rgba(0, 0, 0, 0.3)',
          }}
        >
          <NavbarList activeHash={activeHash} />
          <div
            style={{
              margin: '10px',
            }}
            onClick={toggleMenu}
          >
            <FontAwesomeIcon icon={faTimes} size='lg' />
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
