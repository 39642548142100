import { useState } from 'react'


export const ReadMore = ({ id, text, amountOfWords = 36 }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const splittedText = text.split(' ')
  const itCanOverflow = splittedText.length > amountOfWords
  const beginText = itCanOverflow
    ? splittedText.slice(0, amountOfWords - 1).join(' ')
    : text
  const endText = splittedText.slice(amountOfWords - 1).join(' ')
  
  const handleKeyboard = (e) => {
    if (e.code === 'Space' || e.code === 'Enter') {
      setIsExpanded(!isExpanded)
    }
  }

  return (
    <p id={id}>
      {beginText}
      {itCanOverflow && (
        <>
          {!isExpanded && <span style={{ color: '#01705D', fontWeight: '600' }}> ...</span>}
          <span
            style={{
              display: !isExpanded ? 'none' : 'inline',
            }}
            aria-hidden={!isExpanded}
          >
            {' '}{endText}
          </span>
          <span
            style={{
              color: '#01705D',
              fontWeight: '600',
            }}
            role="button"
            tabIndex={0}
            aria-expanded={isExpanded}
            aria-controls={id}
            onKeyDown={handleKeyboard}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? '  Read Less' : 'Read More'}
          </span>
        </>
      )}
    </p>
  )
}