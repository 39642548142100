import React, { useState } from 'react';

import { Container } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { ReadMore } from './ReadMore';

import './AboutUs.css';

const aboutUsDescriptionText = "Manob Sheba O Shamajik Unnayan Shangstaha (MSSUS) is a non-profit and non-government organization initiated in 1998 to help spearhead community driven development initiatives and enable needy community groups to participate in integrated activities to achieve quality livelihood and be self-sustainable. MSSUS delivers its activities through the power and spirit of volunteerism approach to building sustainable communities in Bangladesh. The organization's main purpose is to promote youth employment and information exchange among the target community, youth mobility, social inclusion, community development, peace among the diverse communities, understanding, and unity purpose. MSSUS started working with the right base approach focusing on governance, and gender (power dynamics between men & women). Adolescent reproductive health, education, legal rights, empowering women through income-generating activities, malaria and TB awareness and advocacy, etc."
const ourMissionText = "MSSUS is committed to create and enriched, productive, and economically independent society that is free from poverty and where its people can exercise their basic social rights especially focused on women and child rights.";
const ourVisionText = "Justice for everyone in a prosperous society."

function AboutUs() {
  const [key, setKey] = useState('our-mission');

  return (
    <Container id='about-us'>
      <div className='about-us'>
        <div className='about-us-left-container about-us-left-background'>
          <div
            style={{
              padding: '80px',
              fontSize: '28px',
              color: '#075A4C',
              fontWeight: '600',
              textAlign: 'center',
              height: 'inherit',
            }}
          >
            Pioneer in providing legal support for women & children abuse, violence, and harassment in the society.
          </div>

        </div>

        <div
          style={{
            flex: 1,
            height: 'inherit',
            // width: '592px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <h1 fontWeight='600'>About Us</h1>
          <div
            style={{
              paddingTop: '5px',
            }}
          >
            <ReadMore
              id="read-more-text"
              text={aboutUsDescriptionText}
              amountOfWords={36}
            />
          </div>

          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
            fill
            justify
          >
            <Tab eventKey="our-mission" title="Our Mission">
              {ourMissionText}
            </Tab>
            <Tab eventKey="our-vision" title="Our Vision">
              {ourVisionText}
            </Tab>
          </Tabs>
          <p>
          </p>

        </div>
      </div>
    </Container>
  );
}

export default AboutUs;
