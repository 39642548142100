
import React from 'react';

import './Navbar.css';
import './NavbarList.css';

function NavbarList({ activeHash }) {
  return (
    <ul className='nav-list-ul'>
      <li><a className={`nav-a ${activeHash === '#about-us' ? 'nav-active' : ''}`} href="#about-us">About Us</a></li>
      <li><a className={`nav-a ${activeHash === '#legal-status' ? 'nav-active' : ''}`} href="#legal-status">Legal Status</a></li>
      <li><a className={`nav-a ${activeHash === '#our-governance' ? 'nav-active' : ''}`} href="#our-governance">Our Governance</a></li>
      <li><a className={`nav-a ${activeHash === '#our-activities' ? 'nav-active' : ''}`} href="#our-activities">Our Activities</a></li>
      <li><a className={`nav-a ${activeHash === '#contact-us' ? 'nav-active' : ''}`} href="#contact-us">Contact Us</a></li>
    </ul>
  );
}

export default NavbarList;
