// CustomDot.jsx
import React from 'react';

const CustomDot = ({ onClick, isSelected }) => {
  return (
    <div
      onClick={onClick}
      style={{
        width: 15,
        height: 15,
        borderRadius: '50%',
        backgroundColor: isSelected ? '#003831' : '#01705D',
        margin: '0 5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease'
      }}
    />
  );
};

export default CustomDot;
