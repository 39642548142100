import React from 'react';

export default function StatsCard({ icon, count, description }) {
  return (

    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '15px',
      }}
    >
      <div
        style={{
          display: 'flex',
          borderRadius: '50%',
          backgroundColor: '#fff',
          border: '1.5px solid #01705D',
        }}
      >
        <div style={{
          width: '80px',
          height: '80px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <img width='40px' height='40px' src={icon} alt='icon' />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flex: '0 0 60%',
        }}
      >
        <div
          style={{
            fontSize: '32px',
            fontWeight: '600',
          }}
        >
          {count}
        </div>
        <div>
          {description}
        </div>
      </div>
    </div>
  );
}


