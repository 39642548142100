import React from 'react';
import Logo from '../assets/MSSUS Logo.png';
import { Container } from 'react-bootstrap';
import OnTheTopIcon from '../assets/on-the-top.png';

import './Footer.css';

export default function Footer() {


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (

    <div className='footer-background'>
      <Container
        id='contact-us'
        style={{
          paddingBottom: '30px',
        }}
      >
        <div className='footer-container'>
          <div>
            <h3>Get In Touch</h3>

            <div
              style={{
                marginTop: '20px',
                marginBottom: '20px',
                width: '100%',
                height: '1px',
                backgroundColor: '#075A4C',
              }}
            />

            <div className='footer-info'>
              <div>
                <h4>
                  Address
                </h4>

                <div
                  style={{
                    marginTop: '20px',
                  }}
                >
                  <div
                    style={{
                      fontSize: '18px',
                    }}
                  >
                    MSSUS Head Office
                  </div>
                  <div
                    style={{
                      width: '200px',
                    }}
                  >
                    1, Boyra Market Road, Kulna-90000, Bangladesh
                  </div>
                </div>

                <div
                  style={{
                    marginTop: '20px',
                  }}
                >
                  <div
                    style={{
                      fontSize: '18px',
                    }}
                  >
                    Branch Office
                  </div>
                  <div
                    style={{
                      width: '200px',
                    }}
                  >
                    37, khan Jahan Ali Road, Khulna-9100, Bangladesh
                  </div>
                </div>

              </div>

              <div>
                <h4>Call</h4>


                <div
                  style={{
                    marginTop: '20px',
                  }}
                >
                  +880 1715 106 890 <br />
                  +880 412 850 277
                </div>

              </div>

              <div>
                <h4>Email</h4>


                <div
                  style={{
                    marginTop: '20px',
                  }}
                >
                  shamimasultanashilu@gmail.com <br />
                  mssus.khulna@gmail.com
                </div>

              </div>



            </div>

          </div>

          <div>
            <img width='140px' src={Logo} alt='logo' />
          </div>


        </div>



        <div
          style={{
            width: '100%',
            height: '1px',
            backgroundColor: '#075A4C',

          }}
        />

        <div
          style={{
            marginTop: '25px',
            display: 'flex',
            gap: '10px',
            justifyContent: 'space-between',
          }}
        >

          <div
            style={{
              flex: '0 0 60%',
            }}
          >
            Copyright 2024. All Right Reserved.</div>
          <div
            style={{
              flex: '0 0 40%',
              textAlign: 'right',
              paddingRight: '10px',
            }}
          >
            <div
              style={{
                cursor: 'pointer',
              }}
              onClick={scrollToTop}
            >
              <img src={OnTheTopIcon} alt='on the top' />Go to Top
            </div>
          </div>


        </div>

      </Container>
    </div>
  );
}
