import { useRef, useState, useEffect } from 'react';
import Header from './components/Header';

import 'bootstrap/dist/css/bootstrap.min.css';

import Hero from './components/Hero';
import Stats from './components/Stats';
import AboutUs from './components/AboutUs';
import LegalStatus from './components/LegalStatus';
import OurGovernance from './components/OurGovernance';
import OurActivities from './components/OurActivities';
import Footer from './components/Footer';

import HeroImage1 from "./assets/slider-images/Conference on womens role in union parishad election.jpg";
import DynamicBackgroundComponent from './components/DynamicBackgroundComponent';

import initialBackground from './assets/slider-images/Legal advice for violance against women.jpg'; // Adjust the path if necessary
import newBackground from './assets/slider-images/Seminar on drug abuse and violance against women.jpg'; // Adjust the path if necessary

import './App.css';

function App() {

  const [currentImage, setCurrentImage] = useState(
    {
      text: "Conference on women's role in union parishad election",
      image: HeroImage1, //"./assets/slider-images/Conference on women's role in union parishad election.jpg",
    }
  );
  const headerRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  console.log(HeroImage1);

  return (
    <div>
      <div style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `url('${currentImage.image}')`,
      }}>
        <div className='hero-background-blend'>

          <div ref={headerRef} className={isFixed ? 'fixed-header' : 'sticky-header'}>
            <Header />
          </div>
          <Hero setCurrentImage={setCurrentImage} />

        </div>
      </div>
      <Stats />
      <AboutUs />
      <LegalStatus />
      <OurGovernance />
      <OurActivities />
      <Footer />
    </div>
  );
}

export default App;
