import React from 'react';
import { Container } from 'react-bootstrap';
import StatsCard from './StatsCard';

import './Stats.css';

import EventsCompletedIcon from '../assets/Group 65.png';
import YearsCompletedIcon from '../assets/Years completed.png';
import ProjectsCompletedIcon from '../assets/Projects Completed.png';

function Stats() {
  return (
    <Container className='stats-container'>

      <StatsCard icon={EventsCompletedIcon} count='500+' description='Events Completed' />

      <div className='stats-divider' />

      <StatsCard icon={YearsCompletedIcon} count='25+' description='Years Completed' />

      <div className='stats-divider' />

      {/* <StatsCard icon={ProjectsCompletedIcon} count='200+' description='Projects Completed' /> */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '15px',
        }}
      >
        <div
          style={{
            display: 'flex',
            borderRadius: '50%',
            backgroundColor: '#fff',
            border: '1.5px solid #01705D',
          }}
        >
          <div style={{
            width: '80px',
            height: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <img width='48px' height='48px' src={ProjectsCompletedIcon} alt='icon' />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flex: '0 0 60%',
          }}
        >
          <div
            style={{
              fontSize: '32px',
              fontWeight: '600',
            }}
          >
            200+
          </div>
          <div>
            Projects Completed
          </div>
        </div>
      </div>

    </Container >
  );
}

export default Stats;
