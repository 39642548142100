// CarouselComponent.jsx
import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import CustomDot from './CustomDot';
import './CarouselComponent.css'; // Import the CSS file

import HeroImage1 from "../assets/slider-images/Conference on womens role in union parishad election.jpg";
import HeroImage2 from '../assets/slider-images/Legal advice for violance against women.jpg';
import HeroImage3 from '../assets/slider-images/Seminar on drug abuse and violance against women.jpg';
import HeroImage4 from '../assets/slider-images/View exchange among underprivilaged women of Sundarbans.jpg';
import HeroImage5 from '../assets/slider-images/Seminar on children welfare from underprivileged working families.jpg';
// import HeroImage5 from '../assets/slider-images/View exchange for child care and child marriage prevention.jpg';

const CarouselComponent = ({ setCurrentImage }) => {

  const carouselItems = [
    {
      text: "Conference on women's role in union parishad election",
      image: HeroImage1,
    },
    {
      text: "Legal advice for violance against women",
      image: HeroImage2,
    },
    {
      text: "Seminar on drug abuse and violance against women",
      image: HeroImage3,
    },
    {
      text: "View exchange among underprivilaged women of Sundarbans",
      image: HeroImage4,
    },
    {
      text: "Seminar on children welfare from underprivileged working families",
      image: HeroImage5,
    },
  ];


  const handleSlideChange = (index) => {
    console.log(index);
    setCurrentImage(carouselItems[index]);
  };

  return (
    <>

      <Carousel
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={2500}
        transitionTime={500}
        renderIndicator={(clickHandler, isSelected, index, label) => (
          <CustomDot onClick={clickHandler} isSelected={isSelected} />
        )}
        onChange={handleSlideChange}
      >
        {carouselItems.map((item, index) => (
          <div key={index} className='carousel-div'>
            <div className='carousel-text'>
              {item.text}
            </div>
            <div className='carousel-image'>
              <img borderRadius='15px' src={item.image} alt='hero' />
            </div>
          </div>

          // <div key={index} className="carousel-slide">
          //   <div className="carousel-left">
          //     <div className="carousel-text">
          //       <p>{item.text}</p>
          //     </div>
          //     <div className="carousel-dots">
          //       {Array.from({ length: carouselItems.length }).map((_, dotIndex) => (
          //         <CustomDot 
          //           key={dotIndex} 
          //           onClick={() => document.querySelectorAll('.control-dots .dot')[dotIndex].click()} 
          //           isSelected={index === dotIndex} 
          //         />
          //       ))}
          //     </div>
          //   </div>
          //   <div className="carousel-image">
          //     <img src={item.image} alt={`Slide ${index}`} />
          //   </div>
          // </div>
        ))}
      </Carousel>
    </>
  );
};

export default CarouselComponent;
