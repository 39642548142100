import React from 'react';
import { Container } from 'react-bootstrap';
import CarouselComponent from './CarouselComponent';


function Hero({ setCurrentImage }) {

  return (
    <Container>
      <CarouselComponent setCurrentImage={setCurrentImage} />
    </Container>
  );
}

export default Hero;
